<template>
  <div class="home-footer">
    <div>
      <el-row :gutter="60">
        <el-col :span="8">
          <div>
            <img
                :src="require('assets/images/quickLink.png')"
                alt="quick link"
                class="foot-img"
            >
          </div>
          <div class="quick-links">
            <div>
              <a href="#" @click="$router.push('/paper')">Call for Papers</a>
              <a v-for="quicklink in quickLinkData" :href="quicklink.link">
                {{quicklink.text}}
              </a>
            </div>
          </div>
        </el-col>

        <el-col :span="8">
          <div>
            <img
                :src="require('assets/images/contact.png')"
                alt="contact"
                class="foot-img1"
            >
          </div>

          <!--  后续封装成组件，先这样写        -->
          <div class="contact-card">
            <div>
              <span class="contact-card-name">Wei Jiang</span>
            </div>
            <div>
              School of Electrical Engineering, Southeast University
            </div>
            <div>
              <span>TEL: (+86) 13813860619</span>
            </div>
          </div>

          <div class="contact-card">
            <div>
              <span class="contact-card-name">Hui Yang</span>
            </div>
            <div>
              School of Electrical Engineering, Southeast University
            </div>
            <div>
              <span>TEL: (+86) 15251867159</span>
            </div>
            <div>
              E-mail: ispec2021@csee.org.cn
            </div>
          </div>
        </el-col>

        <el-col :span="7" style="margin-left: 20px; margin-top:48px;">
          <div>

          </div>

          <div class="contact-card">
            <div>
              <span class="contact-card-name">Yunyang Wang</span>
            </div>
            <div>
              Chinese Society for Electrical Engineering
            </div>
            <div>
              <span>TEL: 010-63415741</span><span>, (+86) 18511972197</span>
            </div>
            <div>
              E-mail: gjb@csee.org.cn
            </div>
          </div>
        </el-col>

      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return{
      quickLinkData:[
        {
          text:'Full Paper Template',
          link: process.env.BASE_URL+ 'lunwen-mb.doc',
        }, 
        {
          text:'Presentation Template',
          link:process.env.BASE_URL+ 'ispec2021 Presentation Template.pptx',
        },
        {
          text:'Poster Template',
          link:process.env.BASE_URL+ 'Poster Template.pptx',
        },
        {
          text:'iSPEC2020',
          link:'http://ieee-spec.csee.org.cn/2020/',          
        },
        {
          text:'iSPEC2020 Video Playback',
          link:'https://i.printhappy.cn/server/catalog.php?id=30',
        },
        {
          text:'Southeast University',
          link:'https://www.seu.edu.cn/',
        },
        {
          text:'SEU School of Electrical Engineering',
          link:'https://ee.seu.edu.cn/',
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.home-footer{
  //height: 460px;
  background-color: #11A3CA;

  &>div{
    margin: 40px 100px;
  }
}

.foot-img{
  margin-top: 0px;
  margin-bottom: 20px;
  height: 25px;
  width: 200px;
}

.foot-img1{
  margin-top: 0px;
  margin-bottom: 20px;
  height: 23px;
  width: 150px;
}

.quick-links{

  &>div{
    font-size: 14px;
    color: #fff;
    font-family: PingFang SC;
    margin: 3px 0;
    line-height: 24px;

    a{
      display: block;
      text-decoration: none;
      color: #fff;
      :visited{
        color: #FFF100;
      }
    }

  }
}

.contact-card{
  font-family: "PingFang SC";
  font-size: 14px;
  color: white;
  margin-bottom: 35px;

  .contact-card-name{
  font-family: "PingFang SC";
  }
}

</style>
