<template>
    <el-header style="height: 219px; padding: 0">
      <div class="img-container">
        <img :src="require('assets/images/head1.png')" class="hl-img" alt="大会图标">
      </div>
      <Menu/>
    </el-header>

</template>

<script>
import Menu from "./Menu";

export default {
  name: "Header",
  components:{
    Menu,
  }
}
</script>
