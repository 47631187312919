<template>
    <div class="name-container">

   <span class="text-title0">
      SEE YOU IN NANJING, CHINA
   </span>
        <span class="text-title0">
      DECEMBER 23-25, 2021
   </span>


        <div>
            <el-row>
                <el-col :lg="12" :md="24" :sm="24">
                    <div class="left-text name-text">
            <span>
              The IEEE Sustainable Power and Energy Conference (iSPEC) is co-sponsored by the CSEE (Chinese Society for Electrical Engineering) and IEEE PES. It takes place every winter in China since 2019, mirroring the IEEE PES General Meeting in North America every summer. The iSPEC2021 is going to be held in December 23-25 in Nanjing, with the theme of “Energy Transition for Carbon Neutrality.”
            </span>
                    </div>
                </el-col>

                <el-col :lg="12" :md="24" :sm="24">
                    <div class="right-text name-text">
            <span>
            The iSPEC2021 will be a hybrid event, offering worldwide perspective and participation. It provides the opportunity to hear new research, ideas and initiatives; and interact with key decision leaders in the power and energy field. Join iSPEC2021 and you will have the chance to network with a wide range of delegates from the utility, product, consultancy, service and business sectors of the power and energy industry.
            </span>
                    </div>

                </el-col>
            </el-row>
        </div>

        <span class="text-title1">
        Conference Chairs
     </span>

        <div class="name-list1">
            <el-row>
                <el-col :md="12" :sm="12" v-for="(item,index) in nameList1" class="profile">
                    <div>
                        <img :src="require('@/assets/images/home/'+item.avatar)" alt="avatar">
                    </div>
                    <div class="profile-name">{{item.name}}</div>
                    <div>{{item.institution}}</div>
                </el-col>
            </el-row>
        </div>

        <span class="text-title1">
        Keynote Speakers
     </span>

        <div class="name-list2">
            <el-row>
                <el-col :md="8" :sm="12" v-for="(item,index) in nameList2" class="profile">
                    <div>
                        <img :src="require('@/assets/images/home/'+item.avatar)" alt="avatar">
                    </div>
                    <div class="profile-name">{{item.name}}</div>
                    <div>{{item.institution}}</div>
                </el-col>
            </el-row>
        </div>

        <div class="name-list3">
            <el-row>
                <el-col :md="8" :sm="12" v-for="(item,index) in nameList3" class="profile">
                    <div>
                        <img :src="require('@/assets/images/home/'+item.avatar)" alt="avatar">
                    </div>
                    <div class="profile-name">{{item.name}}</div>
                    <div>{{item.institution}}</div>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>

    export default {
        name: "NameCard",
        data() {
            return {
                nameList1: [
                    {
                        name: 'Yinbiao Shu',
                        institution: '(President of CSEE)',
                        avatar: '1-1.png'
                    },
                    {
                        name: 'Jassica Bian',
                        institution: '(President of IEEE PES)',
                        avatar: '1-2.png'
                    },
                ],
                nameList2: [
                    {
                        name: 'Xiaoxin Zhou',
                        institution: '(Academician of Chinese Academy of Sciences)',
                        avatar: '1-3.png'
                    },
                    // {
                    //   name:'Licheng Li',
                    //   institution: '(Academician of China Engineering Academy)',
                    //   avatar:'1-6.png'
                    // },
                    {
                        name: 'Yusheng Xue',
                        institution: '(Academician of China Engineering Academy)',
                        avatar: '1-5.png'
                    }, {
                        name: 'Yulong Ding',
                        institution: '(Fellow of Royal Academy of Engineering)',
                        avatar: '1-4.png'
                    },
                ],
                nameList3: [

                    {
                        name: 'Mohammad Shahidehpour',
                        institution: '(Fellow of American Academy of Engineering)',
                        avatar: '1-7.png'
                    },
                    {
                        name: 'Zi-Qiang Zhu',
                        institution: '(Fellow of Royal Academy of Engineering)',
                        avatar: '1-8.png'
                    },
                    {
                        name: 'Saifur Rahman',
                        institution: '(IEEE Life Fellow)',
                        avatar: '1-9.png'
                    },

                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .name-container {
        width: 100%;
        padding: 70px 0;
    }

    .text-title0 {
        font-family: "Microsoft Yahei";
        font-size: 48px;
        color: #11A3CA;
        margin-top: 10px;
        font: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-title1 {
        font-family: "Microsoft Yahei";
        font-size: 30px;
        color: #fff;
        margin-top: 30px;
        font: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 5px solid #007EAB;
        background-color: #007EAB;
    }

    .name-text {
        line-height: 24px;
        font-family: "PingFangSC-Bold";
        font-size: 16px;
        padding: 50px 0 70px 0;
        height: 135px;
        display: inline-block;
        word-wrap: break-word;
        font-weight: bold;
        color: #666666;

        span {
            display: block;
            width: 80%;
            margin: 0 auto;

        }
    }

    .left-text {
        /*width: 556px;*/
        //margin-left: 360px;
    }

    .right-text {
        /*width: 570px;*/
        //margin-left: 70px;
    }

    .name-list {
        padding: 0 60px;
        //display: flex;
        //justify-content: space-around;
    }

    .profile {
        display: inline-block;
        height: 200px;
        text-align: center;
        font-family: "PingFangSC-Bold";
        font-size: 16px;
        line-height: 21.88px;
        margin: 30px 0;

        .profile-name {
            font-size: 20px;
            font-weight: bold;
        }
    }

    a {
        display: block;
        font-weight: bold;
        text-decoration: underline;
        color: rgb(250, 0, 0);

        :visited {
            color: #003cff;
        }
    }

</style>
