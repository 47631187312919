<template>
  <div>
    <el-row>
      <el-col :md="{span:8, offset:3}">
        <div class="support-title">
          <span>HOST</span>
        </div>
        <div class="support-host">
        </div>

        <div class="support-title">
          <span>SUPPORT</span>
        </div>
        <div class="support-support">
        </div>
      </el-col>

      <el-col :md="{span:9, offset:2}">
        <div class="support-title">
          <span>MEDIA SUPPORT</span>
        </div>
        <div class="support-media">
          
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: "Support"
}
</script>

<style scoped lang="scss">
.support-title{
  color: #fff;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  span{
    background-color: #007EAB;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
  }
}

.support-host{
  background-color: #ffffff;
  padding: 10px 40px;
  margin-bottom: 20px;
  border:3px solid #EEEEEE;
  width:450px;  
  height:80px;   
  background-image:url(../../assets/images/home/host.png); /*图片地址*/  
  background-origin:content;                /*从content区域开始显示背景*/  
  background-position:50% 50%;              /*图片上下左右居中*/  
  background-size:contain;                  /*保持图像本身的宽高比例，将图片缩放到宽度或高度正好适应定义背景的区域*/  
  background-repeat:no-repeat;
}

.support-support{
  background-color: #ffffff;
  padding: 10px 40px;
  margin-bottom: 20px;
  border:3px solid #EEEEEE;
  width:450px;  
  height:80px;   
  background-image:url(../../assets/images/home/supporter.png); /*图片地址*/  
  background-origin:content;                /*从content区域开始显示背景*/  
  background-position:center;              /*图片上下左右居中*/  
  background-size:contain;                  /*保持图像本身的宽高比例，将图片缩放到宽度或高度正好适应定义背景的区域*/  
  background-repeat:no-repeat;
}

.support-media{
  background-color: #ffffff;
  padding: 10px 45px;
  margin-bottom: 30px;
  border:3px solid #EEEEEE;

  width:450px;  
  height:235px;   
  background-image:url(../../assets/images/home/media.png); /*图片地址*/  
  background-origin:content;                /*从content区域开始显示背景*/  
  background-position:50% 50%;              /*图片上下左右居中*/  
  background-size:contain;                  /*保持图像本身的宽高比例，将图片缩放到宽度或高度正好适应定义背景的区域*/  
  background-repeat:no-repeat;

}



.sup-media-img{
//  margin-top: 0px;
//  margin-bottom: 20px;
  height: 250px;
  width: 450px;
}

</style>
