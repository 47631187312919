<template>
  <div style="width: 100%; position: relative; z-index: -999">
    <div class="text-region">
      <div class="t-greeting">Energy Transition for Carbon Neutrality</div>
      <div class="t-date">Nanjing, China｜ December 23-25, 2021</div>
    </div>
    <el-carousel
      indicator-position="inside"
      height="550px"
      arrow="never"
      :interval="5000"
      ref="mycarousel"
    >
      <el-carousel-item v-for="item in 3" :key="item">
        <img
          :src="require('assets/images/home/swiper' + item + '.png')"
          alt="轮播图"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel"
};
</script>

<style scoped lang="scss">
@import "styles/swiper.scss";
</style>
