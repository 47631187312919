<template>
    <div class="confer-zone">
        <el-row :gutter="90">
            <el-col :lg="12" :md="12" :sm="24">
        <span class="text-box1">
          All accepted papers will be included in IEEE Xplore and indexed by EI Compendex.
        </span>

                <a href="http://action.csee.org.cn:8080/ICEE/a/login?redLoginUrl=/a/paper/goPaperindex?activityId=243"
                   target="_blank">

                    <img class="sub-img" :src="require('assets/images/home/submit.png')" alt="Submit Now"/>
                </a>

                <br>

                <span class="text-title1">
          Conference Topics :
        </span>

                <br>
                <span class="text-box2">
          The conference organizing committee invites contributions in all areas related to power
          and energy technologies, including (but not limited to) the following topics:
        </span>

                <el-collapse v-model="activeName" accordion class="topic">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <span style="font-size:20px; color:black">1. New Energy Based Modern Power System</span>
                        </template>
                        <ul>
                            <li>Facilitating high penetration of renewables</li>
                            <li>Integrating multiple energy systems</li>
                            <li>Energy storage and its application</li>
                            <li>System resiliency, economics and stability</li>
                            <li>Energy market and ancillary services</li>
                        </ul>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            <span style="font-size:20px; color:black">2. Energy Efficiency & Low Carbon</span>
                        </template>
                        <ul>
                            <li>Carbon peak and carbon neutrality</li>
                            <li>Alternative fuel and energy source</li>
                            <li>CO2 capture, conversion and utilization</li>
                            <li>Efficiency improvement and CO2 reduction</li>
                            <li>Carbon footprint, audit and transaction</li>
                            <li>Low Carbon policy and planning</li>
                        </ul>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <template slot="title">
                            <span style="font-size:20px; color:black">3. Internet + Intelligent Energy Consumption</span>
                        </template>
                        <ul>
                            <li>Potentiality of flexible resources</li>
                            <li>Identification of customer behavior</li>
                            <li>DER management and demand response</li>
                            <li>Transaction of power consumption right</li>
                            <li>Energy substitution and management</li>
                        </ul>
                    </el-collapse-item>
                    <el-collapse-item name="4">
                        <template slot="title">
                            <span style="font-size:20px; color:black">4. Electronic Power Grid Systems</span>
                        </template>
                        <ul>
                            <li>Modeling and simulations of electronic power grids</li>
                            <li>Stability and reliability of electronic power grid systems</li>
                            <li>Power generation/consumption equipment</li>
                            <li>FACTS, HVDC and smart Transformers</li>
                        </ul>
                    </el-collapse-item>
                    <el-collapse-item name="5">
                        <template slot="title">
                            <span style="font-size:20px; color:black">5. Transportation Electrification</span>
                        </template>
                        <ul>
                            <li> Electric machines and motor drives</li>
                            <li> Smart grids interaction with automotive</li>
                            <li> Electric vehicle system architectures and control</li>
                            <li> Electric aircraft and marine propulsion system</li>
                            <li> Onboard and wireless battery chargers</li>
                        </ul>
                    </el-collapse-item>
                    <el-collapse-item name="6">
                        <template slot="title">
                            <span style="font-size:20px; color:black">6. Emerging Technology Applications</span>
                        </template>
                        <ul>
                            <li> Machine learning and big data analysis</li>
                            <li> Blockchain for distributed application and transaction</li>
                            <li> 5G network and communication</li>
                            <li> Cloud computing and edge computing</li>
                            <li> Internet of Things (IoT) integrated power systems</li>
                            <li> Cyber Security Technology</li>
                        </ul>
                    </el-collapse-item>
                </el-collapse>


            </el-col>

            <el-col :lg="12" :md="12" :sm="24" class="right-cards">
                <!--   日期面板
                /
                <Card class="time-card">
                  <template #title>
                    <span>
                      {{"Important Dates".toUpperCase()}}
                    </span>
                  </template>

                  <div class="card-date-list">
                    <ul>
                      <li v-for="item in dateList">
                        <span class="date-item">{{item.date}}</span>
                        <span class="date-agenda">{{item.agenda}}</span>
              </li>
            </ul>
          </div>
        </Card>
        -->
                <!--important information-->
                <Card class="time-card">
                    <template #title>
            <span>
              {{"NEWEST INFORMATION"}}
            </span>
                    </template>

                    <div class="card-date-list">
                        <ul>
                            <li v-for="item in dateList">
                                <span class="date-item">{{item.date}}</span>
                                <span class="date-agenda" v-html="item.agenda"></span>
                            </li>
                        </ul>
                    </div>
                </Card>

                <!--Tutorials && PANELS面板-->
                <Card>
                    <template #title>
                        <span>{{cardInfo1.title}}</span>
                    </template>
                    <ul class="card-panel">
                        <li v-for="person in cardInfo1.persons">
                            <img :src="require('assets/images/home/'+person.avatar)" alt="avatar">
                            <span class="card-panel-description">{{person.description}}</span>
                        </li>
                    </ul>
                </Card>

                <Card class="last-card">
                    <template #title>
                        <span>{{cardInfo2.title}}</span>
                    </template>
                    <ul class="card-panel">
                        <li v-for="person in cardInfo2.persons">
                            <img :src="require('assets/images/home/'+person.avatar)" alt="avatar">
                            <span class="card-panel-description">{{person.description}}</span>
                        </li>
                    </ul>
                </Card>


            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Card from "../../components/Card";

    const PostponeLink = process.env.BASE_URL + 'postpone.pdf'
    const GuideZhLink = process.env.BASE_URL+ 'iSPEC-guide-zh.pdf';
    const GuideEnLink = process.env.BASE_URL+ 'iSPEC-guide-en.pdf';
    export default {
        name: "ConferenceInfo",
        components: {Card},
        methods: {
            submitNow() {
                window.location.href = "http://action.csee.org.cn:8080/ICEE/a/login?redLoginUrl=/a/paper/goPaper?activityId=243";
            }
        },
        data() {
            return {
                activeName: '1',
                dateList: [
                    {
                        date: 'Conference Guide Book',
                        agenda: `The conference guidebook is now available. <a href="${GuideEnLink}" target="_blank">English Version</a>`
                    },
                    {
                        date: 'Conference Postponement',
                        agenda: `Due to the epidemic, the conference is postponed to December 23-25, 2021. The notice of the conference postponement is <a style="font-weight: bold; color: black" href="${PostponeLink}" target="_blank" > here</a>.`
                    },
                    /*
                    {
                      date:'August 20, 2021',
                      agenda:'Full Paper Submission'
                    },
                    {
                      date:'September 30, 2021',
                      agenda:'Notification of Acceptance'
                    },        {
                      date:'October 20, 2021',
                      agenda:'Final Paper Submission'
                    },*/
                ],
                cardInfo1: {
                    title: 'TUTORIALS',
                    persons: [
                        {
                            avatar: '2-1.png',
                            description: '•  Recent Progress and Project Demonstration of Microgrid Control and Operation (Jinwei He)'
                        },
                        {
                            avatar: '2-2.png',
                            description: '•  Oscillation Issues of New Power System (Xiaorong Xie)'
                        },
                        {
                            avatar: '2-3.png',
                            description: '•  Consequent-Pole Peremanent Magnet Machine Systems and Applications (Kai Wang，Feng Li, Jian Li)'
                        }
                    ]
                },
                cardInfo2: {
                    title: 'PANELS',
                    persons: [
                        {
                            avatar: '2-4.png',
                            description: '•  Special Panel for Modern Power System (Chongqin Kang)'
                        },
                        {
                            avatar: '2-5.png',
                            description: '•  Special Panel for Female Scientists (Ruomei Li)'
                        },
                        {
                            avatar: '2-6.png',
                            description: '•  Special Panel for the Developments for Young Professionals (Wei Gu)'
                        },
                        {
                            avatar: '2-7.png',
                            description: '•  Special Panel for EE Education (Zaijun Wu)'
                        }
                    ]
                }

            }
        }
    }
</script>

<style scoped lang="scss">

    .confer-zone {
        //width: 100%;
        //height: 900px;
        margin-top: 120px;
        margin-bottom: 156px;
        background-image: url("../../assets/images/home/bg1.png");
        padding: 0 100px;
    }

    .text-box1 {
        display: inline-block;
        color: #fff;
        font-family: "PingFangSC";
        height: 50px;
        width: 532px;
        line-height: 36.8px;
        font-size: 22px;
        margin-top: 28px;
        font-weight: bold;
    }

    .text-title1 {
        font-family: "Microsoft Yahei";
        font-size: 30px;
        line-height: 40px;
        color: #fff;
        font: bold;
    }

    .text-box2 {
        display: inline-block;
        //background-color: #007EAB;
        //padding: 23px 22px 30px 13px;
        color: #fff;
        font-family: "PingFangSC";
        line-height: 25px;
        font-size: 20px;
        margin-top: 10px;
        font-weight: 500;
        width: 487px;
    }

    .topic {
        font-family: "PingFangSC";
        margin-top: 10px;
        background-color: #fff;
        opacity: 0.6;
        padding: 15px;

        ul {
            margin-bottom: 0px;

            li {
                font-size: 18px;
            }
        }
    }

    .sub-img {
        cursor: pointer;
        display: inline-block;
        height: 50px;
        width: 230px;
        margin-top: 40px;
        margin-left: 120px;
        margin-bottom: 30px;
    }


    .right-cards {
        position: relative;

        .time-card {
            position: relative;
            top: -60px;
            height: 370px;
        }

        .last-card {
            position: relative;
            top: 40px;
            height: 330px;
        }
    }

    .card-date-list {
        list-style: url("../../assets/images/home/rec1.png");

        ul:first-child {
            color: #484AB5;
        }

        li {
            margin: 20px 0;
        }

        .date-item {
            display: block;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 800;
            height: 40px;
        }

        .date-agenda {
            display: block;
            font-size: 17px;
            color: rgb(29, 29, 29);
            line-height: 22px;
        }
    }

    .card-panel {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            margin: 10px 0;
            height: 60px;

            .card-panel-description {
                margin-left: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                color: #666;
                line-height: 16px;
                width: 300px;
                text-indent: -13px;
                margin-left: 13px;
            }
        }
    }

    .el-collapse-item__header {
        font-size: 30px;
    }
</style>
