<template>

  <div class="home-main" style="width: 100%">
    <div id="img" style="position:absolute;z-index:99;" v-float>
      <a :href="link" target="_blank"><img src="@/assets/images/online.png" style="border: 5px; width: 200px"  alt='img'></a>
    </div>
    <Carousel />
    <!-- 轮播图-->
    <NameCard />
    <ConferenceInfo />
    <Support />
  </div>
</template>

<script>
import Header from "../components/Header";
import MainBody from "./component/MainBody";
import Menu from "../components/Menu";
import Carousel from "./component/Carousel";
import NameCard from "./component/NameCard";
import ConferenceInfo from "./component/ConferenceInfo";
import Support from "./component/Support";
import Footer from "../components/Footer";

export default {
  name: "Home",
  components: {
    NameCard,
    Header,
    MainBody,
    Menu,
    Carousel,
    ConferenceInfo,
    Support,
    Footer
  },
  data(){
    return {
      link: process.env.BASE_URL+ 'info-1210.pdf',
    }
  },
  directives: {
    float: {
      inserted: function (el) {
        var xPos = document.body.clientWidth - 20;
        var yPos = document.body.clientHeight / 2;
        var step = 1;
        var delay = 10;
        var height = 0;
        var Hoffset = 0;
        var Woffset = 0;
        var yon = 0;
        var xon = 0;
        var pause = true;
        var interval;
        el.style.top = yPos;
        function changePos() {
          var width = document.body.clientWidth;
          height = document.body.clientHeight;
          Hoffset = el.offsetHeight;
          Woffset = el.offsetWidth;
          el.style.left = xPos + document.body.scrollLeft + "px";
          var h = yPos + document.body.scrollTop
          h = h>600? h % 600: h
          el.style.top =  h + "px";
          // console.log('change pos', el.style.left, el.style.right)
          if (yon) {
            yPos = yPos + step;
          } else {
            yPos = yPos - step;
          }
          if (yPos < 0) {
            yon = 1;
            yPos = 0;
          }
          if (yPos >= height - Hoffset) {
            yon = 0;
            yPos = height - Hoffset;
          }
          if (xon) {
            xPos = xPos + step;
          } else {
            xPos = xPos - step;
          }
          if (xPos < 0) {
            xon = 1;
            xPos = 0;
          }
          if (xPos >= width - Woffset) {
            xon = 0;
            xPos = width - Woffset;
          }
        }
        function start() {
          el.visibility = "visible";
          interval = setInterval(changePos, delay);
        }
        start();
        el.onmouseover = function () {
          clearInterval(interval);
          interval = null;
        };
        el.onmouseout = function () {
          interval = setInterval(changePos, delay);
        };
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "styles/header.scss";

.el-main {
  width: 100%;
  padding: 0;
}

.home-main {
  width: 100%;
  padding: 0;
  overflow: hidden;
}
</style>
